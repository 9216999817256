import { deleteRecord, get, post, put } from '@/services/api';

export const Categorias = {
    state: () => ({
        categorias: [],
        categoria: null
    }),
    mutations: {
        setCategorias(state, newValue) {
            state.categorias = newValue;
        },
        setCategoria(state, newValue) {
            state.categoria = newValue;
        }
    },
    actions: {
        getCategorias({ commit }) {
            get('/categorias?populate=*').then(
                data => {
                    commit('setCategorias', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setCategorias', null);
            });
        },
        getCategoria({ commit }, id) {
            get('/categorias/'+ id + '?populate=*').then(data => {
                commit('setCategoria', data?.data?.data);
            }).catch(err => {
                console.error(err);
                commit('setCategoria', null);
            });
        },
        deleteCategoria(_, id) {
            return deleteRecord('/categorias', id);
        },
        addCategoria(_, formData) {
            return new Promise((res, rej) => {
                post('/categorias', formData).then(
                    _ => {
                        res('Categoria agregada');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible subir la categoria, inténtelo nuevamente');
                });
            });
        },
        updateCategoria(_, { id, formData }) {
            return new Promise((res, rej) => {
                put('/categorias/' + id, formData).then(
                    _ => {
                        res('Categoria editada');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar la categoria, inténtelo nuevamente');
                });
            });
        }
    }
}