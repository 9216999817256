import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Snotify, { SnotifyPosition } from 'vue-snotify'
import vToolTip from 'v-tooltip'
//pipes
import { currencyPipe, datePipe, dateTimePipe, truncatedPipe, uppercasePipe, nullablePipe, noUnderscorePipe } from './helpers/pipes';

Vue.filter('currency', currencyPipe);
Vue.filter('date', datePipe);
Vue.filter('dateTime', dateTimePipe);
Vue.filter('truncated', truncatedPipe);
Vue.filter('uppercase', uppercasePipe);
Vue.filter('nullable', nullablePipe);
Vue.filter('noUnderscore', noUnderscorePipe);

Vue.use(Snotify, {
  toast: {
    timeout: 3000,
    showProgressBar: false,
    closeOnClick: false,
    pauseOnHover: true,
    position: SnotifyPosition.rightBottom,
    icon: false
  }
});

Vue.use(vToolTip);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
