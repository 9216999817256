import { deleteRecord, get, post, put } from '@/services/api';

export const Promociones = {
    state: () => ({
        promociones: [],
        promocion: null
    }),
    mutations: {
        setPromociones(state, newValue) {
            state.promociones = newValue;
        },
        setPromocion(state, newValue) {
            state.promocion = newValue;
        }
    },
    actions: {
        getPromociones({ commit }) {
            get('/promociones?populate=*').then(
                data => {
                    commit('setPromociones', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setPromociones', null);
            });
        },
        getPromocion({ commit }, id) {
            get('/promociones/'+ id + '?populate=*').then(data => {
                commit('setPromocion', data?.data?.data);
            }).catch(err => {
                console.error(err);
                commit('setPromocion', null);
            });
        },
        deletePromocion(_, id) {
            return deleteRecord('/promociones', id);
        },
        addPromocion(_, data) {
            return new Promise((res, rej) => {
                post('/promociones', {data: data}).then(
                    _ => {
                        res('Promocion agregada');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible subir la promocion, inténtelo nuevamente');
                });
            });
        },
        updatePromocion(_, { id, data }) {
            return new Promise((res, rej) => {
                put('/promociones/' + id, { data: data }).then(
                    _ => {
                        res('Promocion editada');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar la promocion, inténtelo nuevamente');
                });
            });
        }
    }
}