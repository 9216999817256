import { format } from 'date-fns';

export function currencyPipe(value) {
    if (value == null) {
        return "---";
    }

    if (typeof value !== "number") {
        return value;
    }

    let formatter = new Intl.NumberFormat('es-MX', {
        style: 'currency',
        currency: 'MXN'
    });

    return formatter.format(value);
}

export function uppercasePipe(value) {
    if (value == null) {
        return "---";
    }

    return value.toUpperCase()
}

export function datePipe(value) {
    if (value == null) {
        return "---";
    }

    return format(new Date(value), "dd-MMM-yyyy");
}

export function dateTimePipe(value) {
    if (value == null) {
        return "---";
    }

    return format(new Date(value), "dd-MMM-yyyy hh:mm");
}

export function truncatedPipe(value) {
    if (value == null) {
        return "---";
    }

    return `${value.substring(0, 16)}...`;
}

export function nullablePipe(value) {
    if (value == null) {
        return "---";
    }

    return value;
}

export function noUnderscorePipe(value) {
    if (value == null) {
        return "---";
    }

    return value.toUpperCase().replace(/_/g, ' ');;
}