export const emailRegex = /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const phoneRegex = /^\d{10}$/;

export const required = (value) => !!value || "Campo requerido";
export const requiredFalsy = (value) => value != null || 'Este campo es requerido';
export const email = (value) => emailRegex.test(value) || "Ingrese un correo válido";
export const phone = (value) => phoneRegex.test(value) || "Ingrese un teléfono válido";
export const maxSize = (value) => !value || value.size < 3000000 || "Logo no puede pesar más de 3MB";
export const maxSizeMultiple = (value) => {
    if(!value || value.length == 0) {
        return true;
    }
    let isValid = true;
    value.forEach(item => {
        if(item.size > 3000000) {
            isValid = false;
        }
    });

    if(isValid) {
        return true;
    }
    else {
        return 'Uno de los archivos pesa mas de 3MB, no admitido';
    }
}
export const minOne = (v) => v >= 1 || "Min. 1";
export const minZero = (v) => v >= 0 || "Min. 0";
export const minAlmostZero = (v) => v >= 0.1 || "Min. 0.1";