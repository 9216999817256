import { get, post, put } from '@/services/api';

export const BannerProductos = {
    state: () => ({
        banner: null
    }),
    mutations: {
        setBannerProductos(state, newValue) {
            state.banner = newValue;
        }
    },
    actions: {
        getBannerProductos({ commit }) {
            get('/banner-producto?populate=*').then(
                data => {
                    commit('setBannerProductos', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setBannerProductos', null);
            });
        },
        updateBannerProducto(_, file) {
            return new Promise((res, rej) => {
                post('/upload', file).then(fileData => {
                    let id  = fileData?.data[0]?.id;
                    put('/banner-producto', {
                        data: {
                            foto: id
                        }
                    }).then(_ => {
                        res('Foto subida correctamente');
                    }).catch(err => {
                        console.error(err);
                        rej('No fue posible ligar la foto al registro, inténtalo nuevamente')
                    })
                }).catch(err => {
                    console.error(err);
                    rej('No fue posible subir el archivo, inténtelo nuevamente');
                });
            });
        }
    }
}