<template>
    <v-app>
      <div class="login-container">
        <v-card :loading="loading" max-width="300" elevation="12" class="mx-auto mt-5">
          <template slot="progress">
            <v-progress-linear color="primary" height="10" indeterminate></v-progress-linear>
          </template>
  
          <v-img height="56" class="my-4" :src="require('@/assets/logo.png')" contain></v-img>

          <div style="width: 100%;" class="text-center">
            <strong>v{{version}}</strong>
          </div>
  
          <v-card-title class="justify-center">
            <span class="text-h6">Plataforma</span>
          </v-card-title>
  
          <v-card-text>
            <v-form id="login-form" v-model="valid" @submit.prevent="login">
              <v-row dense>
                <v-col cols="12" md="12">
                  <v-text-field v-model="identifier" :rules="[required]" label="Correo" append-icon="mdi-email" dense></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field v-model="password" :rules="[required]" :type="hide ? 'password': 'text'" :append-icon="hide ? 'mdi-eye': 'mdi-eye-off'" @click:append="hide = !hide" label="Contraseña" dense></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-row dense>
              <v-col cols="12" md="12">
                <v-btn color="primary" type="submit" form="login-form" block>
                  Iniciar sesión
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </div>
    </v-app>
  </template>
  
  <script>
    import { sha512} from 'js-sha512';
    import { required } from '../../helpers/formHelpers';
    import { login, get } from '../../services/api';
    import { version } from '../../../package.json';
  
    export default {
      name: 'Login',
      data: () => ({
        loading: false,
        required,
        valid: false,
        hide: true,
        identifier: null,
        password: null,
        version
      }),
      methods: {
        async login() {
          this.loading = true;
          let data = await login({
            identifier: this.identifier,
            password: sha512(this.password)
          }).catch(err => {
            if(err?.response?.status == 400) {
              this.$snotify.error('Tu usuario no tiene acceso a esta plataforma.');
            }
            else {
              this.$snotify.error('Ha ocurrido un error, inténtelo mas tarde');
            }
            this.loading = false;
          });
  
          sessionStorage.setItem('token', data.jwt);
  
          const { data: { role: { name: rol }, staff: { id: id_staff, tipo: tipo_staff }},  } = await get('/users/me?populate=staff');
  
          if(rol != 'Admin') {
            this.$snotify.error('Tu usuario no tiene acceso a esta plataforma.');
            return;
          }
  
          let session = {
            id: data.user.id,
            email: data.user.email,
            username: data.user.username,
            role: rol,
            id_staff
          };
  
          sessionStorage.setItem("session", JSON.stringify(session));
          
          if(tipo_staff == 'gerente') {
            this.$router.push("/home");
          }
          else if(tipo_staff == 'staff') {
            this.$router.push("/pedidos");
          }
         
          this.loading = false;
        }
      }
    }
  </script>
  
  <style lang="scss" scoped>
    .login-container {
      display: flex;
      justify-content: center;
      align-items: center;
  
      background: inherit;
      width: 100%;
      min-height: 90vh;
    }
  </style>