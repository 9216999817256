import { get, post, put } from '@/services/api';

export const CarouselIntermedio = {
    state: () => ({
        banners: []
    }),
    mutations: {
        setBannerIntermedio(state, newValue) {
            state.banners = newValue;
        }
    },
    actions: {
        getBannerIntermedio({ commit }) {
            get('/banner-intermedio?populate=*').then(
                data => {
                    commit('setBannerIntermedio', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setBannerIntermedio', null);
            });
        },
        deleteBannerIntermedio(_, { banners, id }) {
            return new Promise((res, rej) => {
                let filter = banners.filter(banner => banner.id != id);
                let toUpdate = filter.map(item => item.id);
                put('/banner-intermedio', {
                    data: {
                        foto: toUpdate
                    }
                }).then(_ => {
                    res('Banner borrado correctamente');
                }).catch(err => {
                    console.error(err);
                    rej('No fue posible eliminar el banner seleccionado')
                })
            });
        },
        addBannerIntermedio(_, formData) {
            return new Promise((res, rej) => {
                post('/upload', formData).then(
                    async fileData => {
                        let id = fileData?.data[0]?.id;
                        let { data: { data: { foto: currentArray } } } = await get('/banner-intermedio?populate=*');
                        let newArray = [];
                        if (currentArray == null) {
                            newArray = [id];
                        }
                        else {
                            let array = currentArray.map(item => item.id);
                            array.push(id);
                            newArray = array;
                        }
                        //update record with file upload id
                        put('/banner-intermedio', {
                            data: {
                                foto: newArray
                            }
                        }).then(_ => {
                            res('Fotos actualizadas correctamente');
                        }).catch(err => {
                            console.error(err);
                            rej('No fue posible actualizar el registro, inténtelo nuevamente');
                        });
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible subir la foto, inténtelo nuevamente');
                });
            });
        }
    }
}