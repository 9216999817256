import { deleteRecord, get, post, put } from '@/services/api';
import { differenceInHours } from 'date-fns'

export const Pedidos = {
    state: () => ({
        pedidos: [],
        pedido: null,
        notas: []
    }),
    mutations: {
        setPedidos(state, newValue) {
            state.pedidos = newValue;
        },
        setPedido(state, newValue) {
            state.pedido = newValue;
        },
        setNotas(state, newValue) {
            state.notas = newValue;
        }
    },
    actions: {
        getPedidos({ commit }) {
            get('/pedidos?populate=*&sort=createdAt:desc').then(
                data => {
                    let pedidos = data?.data?.data;
                    for(let item of pedidos) {
                        if(item?.metodo_pago == 'pago_en_sucursal') {
                            item.isPagoSucursal = true;
                        }
                        else {
                            item.isPagoSucursal = false;
                        }

                        if(item.isPagoSucursal == true) {
                            let diff = differenceInHours(new Date(), new Date(item?.createdAt));
                            if(diff > 48) {
                                item.hasExpired = true;
                            }
                            else {
                                item.hasExpired = false;
                            }
                        }
                        else {
                            item.hasExpired = false;
                        }
                    }
                    commit('setPedidos', pedidos);
                }
            ).catch(err => {
                console.error(err);
                commit('setPedidos', null);
            });
        },
        getPedido({ commit }, id) {
            get('/pedidos/'+ id + '?populate=*').then(data => {
                console.log('pedido', data?.data?.data);
                commit('setPedido', data?.data?.data);
            }).catch(err => {
                console.error(err);
                commit('setPedido', null);
            });
        },
        deletePedido(_, id) {
            return deleteRecord('/pedidos', id);
        },
        updatePedido(_, { id, data }) {
            return new Promise((res, rej) => {
                put('/pedidos/' + id, {data: data}).then(
                    _ => {
                        res('Pedido editado');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar el pedido, inténtelo nuevamente');
                });
            });
        },
        updateEstatus(_, data) {
            return new Promise((res, rej) => {
                post('/pedido/updateEstatus', data).then(
                    _ => {
                        res('El cliente fue notificado por correo');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible notificar el cliente, avisar de manera manual');
                });
            });
        },
        notificarGuiaEnvio(_, data) {
            return new Promise((res, rej) => {
                post('/pedido/guiaEnvio', data).then(
                    _ => {
                        res('El cliente fue notificado por correo');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible notificar el cliente, avisar de manera manual');
                });
            });
        },
        enviarLigaPago(_, { id, link }) {
            return new Promise((res, rej) => {
                post('/pedido/liga_pago', {
                    id_pedido: id,
                    link_pago: link
                }).then(
                    _ => {
                        res('Liga de pago enviada');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible enviar liga de pago, inténtelo nuevamente');
                });
            });
        },
        getNotas({ commit }, id) {
            get('/notas-pedidos?populate=*&filters[pedido][id][$eq]='+ id).then(
                data => {
                    commit('setNotas', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setNotas', null);
            });
        },
        agregarNota(_, formData) {
            return new Promise((res, rej) => {
                post('/notas-pedidos', formData).then(
                    _ => {
                        res('Nota agregada correctamente');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible agregar la nota, inténtelo nuevamente');
                });
            });
        },
        intentoEntrega(_, data) {
            return new Promise((res, rej) => {
                post('/pedido/intento_entrega', data).then(
                    _ => {
                        res('El cliente fue notificado por correo');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible notificar el cliente, avisar de manera manual');
                });
            });
        }
    }
}