export const User = {
    state: () => ({
        session: null
    }),
    mutations: {
        setSession(state, newValue) {
            state.session = newValue;
        }
    },
    actions: {
        refreshSession({ commit }) {
            commit('setSession', JSON.parse(sessionStorage.getItem('session')));
        }
    }
}