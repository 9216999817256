import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles 
import 'vuetify/dist/vuetify.min.css'
const vuetify = new Vuetify()

Vue.use(Vuetify);

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
})

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#5A7D3D',
                secondary: '#72a341',
                accent: '#a8ce3a',
                background: '#efefef',
                success: '#94B49F',
                warning: '#ECB390',
                info: '#B7CADB',
                error: '#DF7861',
            }
        }
    }
});
