import { deleteRecord, get, post, put } from '@/services/api';

export const Sucursales = {
    state: () => ({
        sucursales: [],
        sucursal: null
    }),
    mutations: {
        setSucursales(state, newValue) {
            state.sucursales = newValue;
        },
        setSucursal(state, newValue) {
            state.sucursal = newValue;
        }
    },
    actions: {
        getSucursales({ commit }) {
            get('/sucursales').then(
                data => {
                    commit('setSucursales', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setSucursales', null);
            });
        },
        getSucursal({ commit }, id) {
            get('/sucursales/'+ id).then(data => {
                commit('setSucursal', data?.data?.data);
            }).catch(err => {
                console.error(err);
                commit('setSucursal', null);
            });
        },
        deleteSucursal(_, id) {
            return deleteRecord('/sucursales', id);
        },
        addSucursal(_, data) {
            return new Promise((res, rej) => {
                post('/sucursales', {data: data}).then(
                    _ => {
                        res('Sucursal agregada');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible subir la sucursal, inténtelo nuevamente');
                });
            });
        },
        updateSucursal(_, { id, nombre, direccion, gmaps_link, horario_atencion }) {
            return new Promise((res, rej) => {
                put('/sucursales/' + id, {data: {
                    nombre,
                    direccion,
                    gmaps_link,
                    horario_atencion
                }}).then(
                    _ => {
                        res('Sucursal editada');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar la sucursal, inténtelo nuevamente');
                });
            });
        }
    }
}