import { deleteRecord, get, post, put } from '@/services/api';

export const Productos = {
    state: () => ({
        productos: [],
        producto: null
    }),
    mutations: {
        setProductos(state, newValue) {
            state.productos = newValue;
        },
        setProducto(state, newValue) {
            state.producto = newValue;
        },
    },
    actions: {
        getProductos({ commit }) {
            get('/productos?populate=categoria&populate=thumbnail&populate=galeria').then(
                data => {
                    commit('setProductos', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setProductos', null);
            });
        },
        getProducto({ commit }, id) {
            get('/productos/' + id + '?populate=*').then(data => {
                commit('setProducto', data?.data?.data);
            }).catch(err => {
                console.error(err);
                commit('setProducto', null);
            });
        },
        deleteProducto(_, id) {
            return deleteRecord('/productos', id);
        },
        addProducto(_, formData) {
            return new Promise((res, rej) => {
                post('/productos', formData).then(
                    _ => {
                        res('Producto agregado');
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible subir el producto, inténtelo nuevamente');
                });
            });
        },
        updateProducto(_, { producto, id }) {
            return new Promise((res, rej) => {
                put('/productos/' + id, { data: producto }).then(
                    _ => {
                        res('Producto editado');
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar el producto, inténtelo nuevamente');
                });
            });
        },
        changeProductoVisibility(_, { state, id }) {
            return new Promise((res, rej) => {
                put('/productos/' + id, {
                    data: {
                        visible: state
                    }
                }).then(
                    _ => {
                        res('Producto editado');
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar el producto, inténtelo nuevamente');
                });
            });
        },
        async updateProductoWithFile(_, { data, id }) {
            return new Promise(async (res, rej) => {
                let ficha_tecnica_id = null;
                let thumbnail_id = null;
                let galeria_ids = [];

                if (data.thumbnail) {
                    let formData = new FormData();
                    formData.append('files', data.thumbnail);
                    let thumnbail_res = await post('/upload', formData);
                    thumbnail_id = thumnbail_res?.data[0]?.id;
                    data.thumbnail = thumbnail_id;
                }

                if (data.ficha_tecnica) {
                    let formData = new FormData();
                    formData.append('files', data.ficha_tecnica);
                    let ficha_tecnica_res = await post('/upload', formData);
                    ficha_tecnica_id = ficha_tecnica_res?.data[0]?.id;
                    data.ficha_tecnica = ficha_tecnica_id;
                }

                if (data.galeria) {
                    let formData = new FormData();
                    for (let item of data.galeria) {
                        formData.append("files", item);
                    }
                    let galeria_res = await post('/upload', formData);
                    for(let item of galeria_res.data) {
                        galeria_ids.push(item.id)
                    }
                    data.galeria = galeria_ids;
                }

                put('/productos/' + id, {data: data}).then(
                    _ => {
                        res('Producto editado');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar el producto, inténtelo nuevamente');
                });
            });
        }
    }
}