import axios from "axios";

const backendURL = process.env.VUE_APP_STRAPI_URL;

export async function get(route) {
    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': `Bearer ${token}` }

    if (token && headers) {
        return axios.get(backendURL + route, { headers });
    }
    
    return axios.get(backendURL + route);
}

export async function post(route, body) {
    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': `Bearer ${token}` }

    return axios.post(backendURL + route, body, { headers });
}

export async function put(route, body) {
    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': `Bearer ${token}` }

    return axios.put(backendURL + route, body, { headers });
}

export async function deleteRecord(route, id) {
    const token = sessionStorage.getItem('token');
    const headers = { 'Authorization': `Bearer ${token}` }

    return axios.delete(backendURL + route + `/${id}`, { headers });
}

export async function login(body) {
    return new Promise(async (resolve, reject) => {
        try {
            const { data: loginData } = await axios.post(backendURL + '/auth/local', body);
            resolve(loginData);
        } catch (error) {
            reject(error);
        }
    });
}