import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Auth/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Platform/Home.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/carousel-principal',
    name: 'CarouselPrincipal',
    component: () => import('../views/Platform/CarouselPrincipal/CarouselPrincipal.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/carousel-principal-add',
    name: 'CarouselPrincipalAdd',
    component: () => import('../views/Platform/CarouselPrincipal/CarouselPrincipalAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/carousel-intermedio',
    name: 'CarouselIntermedio',
    component: () => import('../views/Platform/CarouselIntermedio/CarouselIntermedio.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/carousel-intermedio-add',
    name: 'CarouselIntermedioAdd',
    component: () => import('../views/Platform/CarouselIntermedio/CarouselIntermedioAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/slider-marcas',
    name: 'SliderMarcas',
    component: () => import('../views/Platform/SliderMarcas/SliderMarcas.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/slider-marcas-add',
    name: 'SliderMarcasAdd',
    component: () => import('../views/Platform/SliderMarcas/SliderMarcasAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/configuracion-global',
    name: 'ConfiguracionGlobal',
    component: () => import('../views/Platform/ConfiguracionGlobal/ConfiguracionGlobal.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/sucursales',
    name: 'Sucursales',
    component: () => import('../views/Platform/Sucursales/Sucursales.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/sucursales-add',
    name: 'SucursalesAdd',
    component: () => import('../views/Platform/Sucursales/SucursalesAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/sucursales-edit/:id',
    name: 'SucursalesEdit',
    component: () => import('../views/Platform/Sucursales/SucursalesEdit.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/staff',
    name: 'Staff',
    component: () => import('../views/Platform/Staff/Staff.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/staff-add',
    name: 'StaffAdd',
    component: () => import('../views/Platform/Staff/StaffAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/staff-edit/:id',
    name: 'StaffEdit',
    component: () => import('../views/Platform/Staff/StaffEdit.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/banner-productos',
    name: 'BannerProductos',
    component: () => import('../views/Platform/BannerProductos/BannerProductos.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/banner-productos-edit',
    name: 'BannerProductosEdit',
    component: () => import('../views/Platform/BannerProductos/BannerProductosEdit.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/servicios',
    name: 'Servicios',
    component: () => import('../views/Platform/Services/Services.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/servicios-add',
    name: 'ServiciosAdd',
    component: () => import('../views/Platform/Services/ServicesAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/servicios-edit/:id',
    name: 'ServiciosEdit',
    component: () => import('../views/Platform/Services/ServicesEdit.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/productos',
    name: 'Productos',
    component: () => import('../views/Platform/Productos/Productos.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/productos-add',
    name: 'ProductosAdd',
    component: () => import('../views/Platform/Productos/ProductosAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/productos-edit/:id',
    name: 'ProductosEdit',
    component: () => import('../views/Platform/Productos/ProductosEdit.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/categorias',
    name: 'Categorias',
    component: () => import('../views/Platform/Categorias/Categorias.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/categorias-add',
    name: 'CategoriasAdd',
    component: () => import('../views/Platform/Categorias/CategoriasAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/categorias-edit/:id',
    name: 'CategoriasEdit',
    component: () => import('../views/Platform/Categorias/CategoriasEdit.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/promociones',
    name: 'Promociones',
    component: () => import('../views/Platform/Promociones/Promociones.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/promociones-add',
    name: 'PromocionesAdd',
    component: () => import('../views/Platform/Promociones/PromocionesAdd.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/promociones-edit/:id',
    name: 'PromocionesEdit',
    component: () => import('../views/Platform/Promociones/PromocionesEdit.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/pedidos',
    name: 'Pedidos',
    component: () => import('../views/Platform/Pedidos/Pedidos.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/pedido-detalle/:id',
    name: 'PedidoDetalle',
    component: () => import('../views/Platform/Pedidos/PedidoDetalle.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
  {
    path: '/pedido/notas/:id',
    name: 'PedidoNotas',
    component: () => import('../views/Platform/Pedidos/PedidoNotas.vue'),
    beforeEnter: (to, from, next) => {
      verifySession(next);
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

const verifySession = (nextRef, internalOnly = false) => {
  const token = sessionStorage.getItem('token');
  let userStringSession = sessionStorage.getItem('session');
  let user = null;
  if(userStringSession) {
    user = JSON.parse(userStringSession) || null;
  }
  
  if (user != null && token != null) {
    nextRef();
    return;
  }
  sessionStorage.removeItem('session');
  sessionStorage.removeItem('token');
  nextRef({ path: '/' });
}

