import { deleteRecord, get, post, put } from '@/services/api';

export const Staff = {
    state: () => ({
        staffs: [],
        staff: null,
        userId: null
    }),
    mutations: {
        setStaffs(state, newValue) {
            state.staffs = newValue;
        },
        setStaff(state, newValue) {
            state.staff = newValue;
        },
        setUserId(state, newValue) {
            state.userId = newValue;
        }
    },
    actions: {
        getStaffs({ commit }) {
            get('/staffs?populate=usuario').then(
                data => {
                    commit('setStaffs', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setStaffs', null);
            });
        },
        getStaff({ commit }, id) {
            get('/staffs/'+ id + '?populate=usuario').then(data => {
                commit('setStaff', data?.data?.data);
                commit('setUserId', data?.data?.data?.usuario?.id);
            }).catch(err => {
                console.error(err);
                commit('setStaff', null);
            });
        },
        deleteStaff(_, id) {
            return deleteRecord('/staffs', id);
        },
        addStaff(_, data) {
            return new Promise((res, rej) => {
                //primero dar de alta el usuario
                post('/users', {
                    username: data.username,
                    email: data.email,
                    password: data.password,
                    confirmed: true,
                    role: 1
                }).then(user => {
                    let id = user?.data?.id;
                    post('/staffs', {data: {
                        nombre_completo: data.nombre_completo,
                        sexo: data.sexo,
                        telefono: data.telefono,
                        tipo: data.tipo,
                        usuario: id
                    }}).then(
                        _ => {
                            res('Staff agregado');  
                        }
                    ).catch(err => {
                        console.error(err);
                        //delete prev created user
                        deleteRecord('/users', id);
                        rej('No fue posible subir el staff, inténtelo nuevamente');
                    });
                }).catch(err => {
                    console.error(err);
                    rej('No fue posible registrar el usuario');
                });
            });
        },
        updateStaff (_, { id, nombre_completo, sexo, telefono, tipo, id_usuario }) {
            return new Promise((res, rej) => {
                put('/users/' + id_usuario, {
                    role: tipo == 'gerente' ? 1 : 4
                });

                put('/staffs/' + id, {data: {
                    nombre_completo,
                    sexo,
                    telefono,
                    tipo
                }}).then(
                    _ => {
                        res('Staff editado');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar el staff, inténtelo nuevamente');
                });
            });
        },
        updatePassword (_, {password, id}) {
            return new Promise((res, rej) => {
                put('/users/' + id, {data: {
                    password: password
                }}).then(
                    _ => {
                        res('Staff editado');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar el staff, inténtelo nuevamente');
                });
            });
        }
    }
}