import { get, put } from '@/services/api';

export const ConfiguracionGlobal = {
    state: () => ({
        config: null
    }),
    mutations: {
        setConfig(state, newValue) {
            state.config = newValue;
        }
    },
    actions: {
        getConfig({ commit }) {    
            get('/configuraciones/1').then(
                data => {
                    commit('setConfig', data?.data?.data);
                }
            ).catch(err => {
                commit('setConfig', null);
            });
        },
        updateConfig(_, data) {
            return new Promise((res, rej) => {
                put('/configuraciones/1', {
                    data: data
                }).then(success => {
                    res('Configuración actualizada');
                }).catch(err => {
                    console.error(error);
                    rej('No se pudo actualizar la configuración, inténtelo nuevamente');
                });
            });
        }
    }
}