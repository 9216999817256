<template>
  <div id="app">
    <router-view />
    <vue-snotify />
    <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
      Actualización disponible
      <v-btn text @click="refreshApp">
        Recargar
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import update from '@/mixins/update';

export default {
  name: 'App',

  data: () => ({
    //
  }),
  mixins: [update]
};
</script>

<style lang="scss">
@import "~vue-snotify/styles/material";
@import "./sass/variables";

.snotify-success {
  background-color: $success;
}

.snotify-warning {
  background-color: $warning;
}

.snotify-info {
  background-color: $info;
}

.snotify-error {
  background-color: $error;
}

#app {
  font-family: sans-serif;

  a {
    text-decoration: none;
  }
}

.toasted {
  font-family: inherit;
}

.pointer {
  cursor: pointer;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  font-family: sans-serif;
  background: #e1e1e1;
  color: black;
  border-radius: 16px;
  padding: 0.5rem;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, 0.1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

::-webkit-scrollbar {
  width: 4px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: rgba($color: #153f53, $alpha: 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba($color: #03989e, $alpha: 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: lighten($color: #03989e, $amount: 10);
}
</style>
