import { deleteRecord, get, post, put } from '@/services/api';

export const Servicios = {
    state: () => ({
        servicios: [],
        servicio: null
    }),
    mutations: {
        setServicios(state, newValue) {
            state.servicios = newValue;
        },
        setServicio(state, newValue) {
            state.servicio = newValue;
        },
    },
    actions: {
        getServicios({ commit }) {
            get('/servicios?populate=thumbnail').then(
                data => {
                    commit('setServicios', data?.data?.data);
                }
            ).catch(err => {
                console.error(err);
                commit('setServicios', null);
            });
        },
        getServicio({ commit }, id) {
            get('/servicios/'+ id + '?populate=thumbnail').then(data => {
                commit('setServicio', data?.data?.data);
            }).catch(err => {
                console.error(err);
                commit('setServicio', null);
            });
        },
        deleteServicio(_, id) {
            return deleteRecord('/servicios', id);
        },
        addServicio(_, data) {
            return new Promise((res, rej) => {
                post('/servicios', {data: data}).then(
                    _ => {
                        res('Servicio agregado');  
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible subir el servicio, inténtelo nuevamente');
                });
            });
        },
        addServicioWithFile(_, formData) {
            return new Promise((res, rej) => {
                post('/servicios', formData).then(
                    _ => {
                        res('Servicio agregado');  
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible subir el servicio, inténtelo nuevamente');
                });
            });
        },
        updateServicio (_, { servicio, id }) {
            return new Promise((res, rej) => {
                put('/servicios/' + id, {data: servicio}).then(
                    _ => {
                        res('Servicio editado');    
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible editar el servicio, inténtelo nuevamente');
                });
            });
        },
        updateServicioWithFile (_, { formData, data, id }) {
            return new Promise((res, rej) => {
                post('/upload', formData).then(fileData => {
                    let idFile  = fileData?.data[0]?.id;
                    data.thumbnail = idFile;
                    put('/servicios/' + id, {data: data}).then(
                        _ => {
                            res('Servicio editado');    
                        }
                    ).catch(err => {
                        console.error(err);
                        rej('No fue posible editar el servicio, inténtelo nuevamente');
                    });
                }).catch(err => {
                    console.error(err);
                    rej('No fue posible subir la foto, intente nuevamente');
                });
            });
        }
    }
}