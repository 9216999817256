import Vue from 'vue'
import Vuex from 'vuex'

//modules
import { User } from './modules/user';
import { CarouselPrincipal } from './modules/carouselprincipal';
import { CarouselIntermedio } from './modules/carouselintermedio';
import { SliderMarcas } from './modules/slidermarcas';
import { ConfiguracionGlobal } from './modules/configuracionglobal';
import { Sucursales } from './modules/sucursales';
import { Staff } from './modules/staff';
import { BannerProductos } from './modules/bannerproductos';
import { Servicios } from './modules/servicios';
import { Productos } from './modules/productos';
import { Categorias } from './modules/categorias';
import { Promociones } from './modules/promociones';
import { Pedidos } from './modules/pedidos';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: false
  },
  getters: {
  },
  mutations: {
    setDrawer(state, newValue) {
      state.drawer = newValue;
    }
  },
  actions: {
  },
  modules: {
    user: User,
    carouselprincipal: CarouselPrincipal,
    carouselintermedio: CarouselIntermedio,
    slidermarcas: SliderMarcas,
    configuracionglobal: ConfiguracionGlobal,
    sucursales: Sucursales,
    staff: Staff,
    bannerproductos: BannerProductos,
    servicios: Servicios,
    productos: Productos,
    categorias: Categorias,
    promociones: Promociones,
    pedidos: Pedidos
  }
})
