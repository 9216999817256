import { get, deleteRecord } from '@/services/api';

export const CarouselPrincipal = {
    state: () => ({
        banners: []
    }),
    mutations: {
        setBanners(state, newValue) {
            state.banners = newValue;
        }
    },
    actions: {
        getBanners({ commit }) {    
            get('/carousel-homes?populate=*').then(
                data => {
                    commit('setBanners', data?.data?.data);
                }
            ).catch(err => {
                commit('setBanners', null);
            });
        },
        deleteBanner(_, id) {
            return deleteRecord('/carousel-homes', id);
        }
    }
}