import { get, post, put } from '@/services/api';

export const SliderMarcas = {
    state: () => ({
        marcas: []
    }),
    mutations: {
        setSliderMarcas(state, newValue) {
            state.marcas = newValue;
        }
    },
    actions: {
        getSliderMarcas({ commit }) {
            get('/proveedor?populate=*').then(
                data => {
                    if(data?.data?.data.foto == null) {
                        commit('setSliderMarcas', []);
                    }
                    else {
                        commit('setSliderMarcas', data?.data?.data?.foto);
                    }
                }
            ).catch(err => {
                console.error(err);
                commit('setSliderMarcas', null);
            });
        },
        deleteSliderMarca(_, array) {
            return put('/proveedor', { data: {
                foto: array
            } });
        },
        addSliderMarca(_, formData) {
            return new Promise((res, rej) => {
                post('/upload', formData).then(
                    async fileData => {
                        let id = fileData?.data[0]?.id;
                        let { data: { data: { foto: currentArray } } } = await get('/proveedor?populate=*');
                        let newArray = [];
                        if(currentArray == null) {
                            newArray = [id];
                        }
                        else {
                            let array = currentArray.map(item => item.id);
                            array.push(id);
                            newArray = array;
                        }
                        //update record with file upload id
                        put('/proveedor', {
                            data: {
                                foto: newArray
                            }
                        }).then(_ => {
                            res('Fotos actualizadas correctamente');
                        }).catch(err => {
                            console.error(err);
                            rej('No fue posible actualizar el registro, inténtelo nuevamente');
                        });
                    }
                ).catch(err => {
                    console.error(err);
                    rej('No fue posible subir la foto, inténtelo nuevamente');
                });
            });
        }
    }
}